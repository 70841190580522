.image-container {
  padding-bottom: 100%; /* 1:1 aspect ratio */
  position: relative;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
}

.image-container:hover {
  cursor: pointer;
}

/* #popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    transition: 250ms transform;
    padding: 50px;
    z-index: 2;
}

#selectedImage {
    max-height: 80%;
    max-width: 80%;
    border-radius: 10px;
} */

#popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-100%);
  pointer-events: auto;
}

#popup .actions {
  position: absolute;
  top: 5rem;
  /* right: 10px; */
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

#selectedImage {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
}
